import React from 'react';
// import { TeamOutlined, VerifiedOutlined, BarsOutlined, FormOutlined, DollarOutlined} from '@ant-design/icons'
import Loadable from 'react-loadable';
import Loading from '../pages/components/Loading'

const MerchantsIndex = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/merchants/index'),loading: Loading});
const MerchantsDetail = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/merchants/detail'),loading: Loading});
const CustomerIndex = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/customer/index'),loading: Loading});
const CustomerDetail = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/customer/detail'),loading: Loading});
const CustomerAdd = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/customer/add'),loading: Loading});
const SalesmanIndex = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/salesman/index'),loading: Loading});
const SalesmanDetail = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/salesman/add'),loading: Loading});
const SalesmanRole = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/salesman/role'),loading: Loading});
const SalesmanRoleEdit = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/salesman/roleEdit'),loading: Loading});
const OrderIndex = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/order/index'),loading: Loading});
const OrderDetail = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/order/detail'),loading: Loading});
const JielongIndex = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/jielong/index'),loading: Loading});
const JielongDetail = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/jielong/detail'),loading: Loading});
const SiyuIndex = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/siyu/index'),loading: Loading});
const SiyuDetail = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/siyu/detail'),loading: Loading});
const Services = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/services'),loading: Loading});
const ServicesDetail = Loadable({loader: () => import(/*webpackChunkName:'GroupFans'*/'../pages/services/detail'),loading: Loading});
const Error404 = Loadable({loader: () => import(/*webpackChunkName:'Error404'*/'../pages/error/404'),loading: Loading});
const Error401 = Loadable({loader: () => import(/*webpackChunkName:'Error401'*/'../pages/error/401'),loading: Loading});
const Index = Loadable({loader: () => import(/*webpackChunkName:'Index'*/'../pages/index/index'),loading: Loading});

// 所有路由组件
const allComments = [
    { path: "/sidebar/merchants/index", component: <MerchantsIndex/>, name:'餐饮商户列表' },// 餐饮商户列表
    { path: "/sidebar/merchants/detail", component: <MerchantsDetail/>, name:'门店信息' },// 餐饮商户详情
    { path: "/sidebar/customer/index", component: <CustomerIndex/>, name:'意向客户列表' },// 意向客户列表
    { path: "/sidebar/customer/detail", component: <CustomerDetail/>, name:'客户详情' },// 意向客户详情
    { path: "/sidebar/customer/add", component: <CustomerAdd/>, name:'新增意向客户' },// 意向客户新增
    { path: "/sidebar/salesman/index", component: <SalesmanIndex/>, name:'业务员列表' },// 业务员列表
    { path: "/sidebar/salesman/add", component: <SalesmanDetail/>, name:'业务员详情' },// 业务员详情
    { path: "/sidebar/salesman/role", component: <SalesmanRole/>, name:'业务员角色' },// 业务员角色
    { path: "/sidebar/salesman/roleEdit", component: <SalesmanRoleEdit/>, name:'业务员角色编辑' },// 业务员角色编辑
    { path: "/sidebar/order/index", component: <OrderIndex/>, name:'订单列表' },// 订单列表
    { path: "/sidebar/order/detail", component: <OrderDetail/>, name:'订单详情' },// 订单列表
    { path: "/sidebar/jielong/index", component: <JielongIndex/>, name:'接龙圈主列表' },// 接龙圈主列表
    { path: "/sidebar/jielong/detail", component: <JielongDetail/>, name:'接龙圈主列表' },// 接龙圈主列表
    { path: "/sidebar/siyu/index", component: <SiyuIndex/>, name:'私域增长列表' },// 私域增长列表
    { path: "/sidebar/siyu/detail", component: <SiyuDetail/>, name:'私域增长信息' },// 私域增长列表
    { path: "/sidebar/services/index", component: <Services/>, name:' 增值服务' },// 增值服务列表
    { path: "/sidebar/services/detail", component: <ServicesDetail/>, name:' 增值服务' },// 增值服务编辑/详情
    { path: "/error/404", component: <Error404 />, name:'404'},       // 404
    { path: "/error/401", component: <Error401 />, name:'401'},       // 401
    { path: "/index", component: <Index />, name:'首页'},       // 404
]


// 左侧导航 
const menus = [
    {
        id: '0',
        path: '/sidebar/merchants',
        icon: 'agent',
        name: '餐饮商户管理',
        children: [
            {
                id: '0_1',
                path: '/sidebar/merchants/index',
                name: '餐饮商户列表',
            },
        ]        
    },
    {
        id: '1',
        path: '/sidebar/jielong',
        icon: 'jielong',
        name: '接龙圈主管理',
        children: [
            {
                id: '1_1',
                path: '/sidebar/jielong/index',
                name: '接龙圈主列表',
            },
        ]    
    },
    {
        id: '2',
        path: '/sidebar/siyu',
        icon: 'siyu',
        name: '私域增长管理',
        children: [
            {
                id: '2_1',
                path: '/sidebar/siyu/index',
                name: '私域增长列表',
            },
        ] 
    },
    {
        id: '3',
        path: '/sidebar/customer',
        icon: 'merchant',
        name: '意向客户',
        children: [
            {
                id: '3_1',
                path: '/sidebar/customer/index',
                name: '意向客户列表',
            },
        ]    
    },
    {
        id: '4',
        path: '/sidebar/salesman',
        icon: 'salesman',
        name: '业务员管理',
        children: [
            {
                id: '4_1',
                path: '/sidebar/salesman/index',
                name: '业务员列表',
            },
            {
                id: '4_2',
                path: '/sidebar/salesman/role',
                name: '业务员角色',
            },
        ]
    },
    {
        id: '5',
        path: '/sidebar/order',
        icon: 'order',
        name: '订单管理',
        children: [
            {
                id: '5_1',
                path: '/sidebar/order/index',
                name: '订单列表',
            },
        ]
    },
    {
        id: '6',
        path: '/sidebar/services',
        icon: 'merchant',
        name: '增值服务',
        children: [
            {
                id: '6_1',
                path: '/sidebar/services/index',
                name: '增值服务列表',
            },
        ]
    },
    // {
    //     id: '7',
    //     path: '/sidebar/system',
    //     icon: '',
    //     name: '系统管理',
    //     children: [
    //         {
    //             id: '7_1',
    //             path: '/sidebar/system/user',
    //             name: '用户管理',
    //             icon: '',
    //             children: [
    //                 {
    //                     id: '7_1_1',
    //                     path: '/sidebar/system/user1',
    //                     name: '用户管理',
    //                     icon: '',
    //                 }
    //             ]
    //         },
    //         {
    //             id: '7_2',
    //             path: '/sidebar/system/role',
    //             name: '角色管理',
    //             icon: '',
    //         },
    //         {
    //             id: '7_3',
    //             path: '/sidebar/system/menu',
    //             name: '权限管理',
    //             icon: '',
    //         }
    //     ]
    // },
]

export {
    allComments,
    menus
}