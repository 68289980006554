import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react'
import { ConfigProvider } from 'antd';
import * as serviceWorker from './serviceWorker';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { stores } from './stores/store';
import App from './App';


import './index.css';
ReactDOM.render(
  <Provider {...stores}>
    <ConfigProvider locale={zh_CN}>
        <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
