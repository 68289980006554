import React from 'react';
import { inject , observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Layout, Form, Input, Button, Card, message } from 'antd'
import CaptchaMini from 'captcha-mini'
// import sha1 from 'sha1' 
// import { login, sendSms } from '@/services/login'
// import { getUserMenusList } from '@/services/system'
import { login, sendSms } from '../../services/login'

import './index.scss'


@withRouter
@inject('userStore')
@observer
class LoginComponent extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            second:0,
            code:'',
            mobile:'',
            password:'',
            svg_code:'',
            currentSvgCode:'',
            buttonLoading:false,
            loginLoding:false,
            login_type: 0 ,
        }
    }

    componentWillMount(){
        this.props.userStore.getToken()
        const token = this.props.userStore.token
        if(token){
            this.props.history.push('/')
        }
    }
     
    componentDidMount(){
        /*不传值，默认配置*/
        let svg_captcha = new CaptchaMini(); 
        svg_captcha.draw(document.querySelector('#svg_captcha'), r => {
            this.setState({
                currentSvgCode:r
            })
        }); 
    }

    onFinish = values  => {

        const { currentSvgCode, login_type } = this.state

        if(!(/^1[3456789]\d{9}$/.test(values.mobile))){ 
            message.error('请填写正确的手机号码')
            return
        } 

        if(!this.compare(currentSvgCode,values.svg_code)){ 
            message.error('请填写正确的图形验证码')
            return
        } 

        let params = {
            mobile:values.mobile,
        }
        if(login_type === 0){
            params.password = values.password+''
        }else{
            params.code = values.code+''
        }

        this.setState({
            loginLoding:true
        })
        login(params).then((res)=>{
            this.setState({
                loginLoding:false
            })
            //登录成功后缓存Token值，设置全局UserInfo
            this.props.userStore.setUserInfo(res)
            this.props.userStore.setToken(res.authorization.token)
            this.props.history.push('/')      
            //登录成功获取菜单
            // getUserMenusList({}).then((menu)=>{
            //     this.props.userStore.setMenuList(menu)
            //     this.props.history.push('/')            
            // }).catch((error)=>{
            //     this.setState({
            //         loginLoding:false
            //     })
            //     message.error(error)
            // })
        }).catch((error)=>{
            this.setState({
                loginLoding:false
            })
            message.error(error)
        })
    };     

    sendVerifyCode = ()=> {
        const { second, mobile } = this.state
        if(!(/^1[3456789]\d{9}$/.test(mobile))){ 
            message.error('请填写正确的手机号码')
            return
        } 
        if (second !== 0) {
          return
        }
        this.setState({
            buttonLoading:true
        })
        sendSms({mobile:mobile}).then((res)=>{
            this.countdown(60)
            this.setState({
                buttonLoading:false
            })
            message.success('短信发送成功，请注意查收')
        }).catch((error)=>{
            this.setState({
                buttonLoading:false
            })
            message.error(error||'获取验证码失败')
        })
    }

    countdown = (max) => {
        let second = max || this.state.second
        if (second === 0) {
        this.setState({ second: 0 })
            return
        }
        this.setState({ second: second - 1 })
        setTimeout(() => this.countdown(), 1000)
    }

    fieldChange = (e,key) => {
        this.setState({
           [key]: e.target.value.trim(),
        })
    }

    compare = (str,compareStr) => {
        //不区分大小写
        if(str.toLowerCase() === compareStr.toLowerCase()) {
            return true;
        } else {
            return false;
        }
    }

    render(){
        const { second, buttonLoading, loginLoding, login_type } = this.state

        return (
            <Layout 
                className="login"
                style={{ 
                    textAlign:'center' ,
                    backgroundImage:'url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg)',
                    height:'100vh'
                }}
            >
                <Card
                    style={{ width:400,position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)' }}
                >
                    <h1>私域代理商</h1>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                    >
                        <Form.Item
                            name="mobile"
                            rules={[{ required: true, message: '请输入手机号！' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入手机号"  onChange={(e)=>{this.fieldChange(e,'mobile')}}/>
                        </Form.Item>
                        {
                            login_type === 0 ? (
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: '请输入用户密码！' }]}
                                >
                                    <Input
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        type="password"
                                        placeholder="请输入用户密码"
                                        onChange={(e)=>{this.fieldChange(e,'password')}}
                                    />
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    name="code"
                                    rules={[{ required: true, message: '请输入手机验证码！' }]}
                                >                        
                                    <Input 
                                        placeholder="手机验证码" 
                                        onChange={(e)=>{this.fieldChange(e,'code')}}
                                        addonAfter={
                                            <Button  className="code_button" loading={buttonLoading} type="primary" onClick={this.sendVerifyCode.bind(this)} disabled = {second > 0 ? true:false}>获取验证码{second || null}</Button>
                                        }
                                    />
                                </Form.Item>
                            ) 
                        }
                        <Form.Item
                            name="svg_code"
                            rules={[{ required: true, message: '请输入图形验证码！' }]}
                        >                           
                            <Input placeholder="图形验证码"  addonAfter={<canvas width="120" height="40" id="svg_captcha" className="svg_captcha"></canvas>}/>
                        </Form.Item>
                        <div className="login_type" onClick={()=>{ this.setState({login_type:login_type ===0 ? 1 : 0}) }}>{login_type===0?'验证码登录':'账号密码登录'}</div>
                        <Form.Item>
                            <Button loading={loginLoding} type="primary" htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Layout>
        )
    }
}

export default LoginComponent;