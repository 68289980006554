import { request } from '../api/http';

//登录
export async function login(params) {
    return request({
        url: '/mobileLogin',
        params,
        method:'post'
    });
}

//发送验证短信
export async function sendSms(params) {
    return request({
        url: '/pullSmsCode',
        params,
        method:'get'
    });
}

