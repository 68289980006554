import React from 'react'
import { Layout, Menu,Avatar,BackTop,Image } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import { allComments, menus } from '../router/routerConfig'
import HeadLabel from './components/head_label'
import { withRouter,Switch, Route, Redirect, Link } from "react-router-dom";
import back_up from '../assets/images/back_up.png'

import './main.scss'

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

@withRouter
class Main extends React.Component {
  constructor(props){
    super(props)
  }

  state = {
    collapsed: false,
    openKey:[]
  };

  componentWillMount(){
    //获取多级菜单
    const menuTreeNode = this.getMenuNodes(menus);
    this.setState({
      menuTreeNode,
    });
  
  }  

  //渲染多级菜单
  // 菜单渲染
  getMenuNodes = (menuList) => {
    // 得到当前请求的路由路径
    const path = this.props.location.pathname;
    return menuList.reduce((pre, item) => {
      if (this.filterMenuItem(item)) {
        if (!item.children||item.children.length===0||(item.children.length>0&&(item.children.filter(itemC=>!itemC.hideMenu)).length===0)) {
        // if (!item.children) {
          pre.push(
            <Menu.Item key={item.path}>
              <Link to={item.path}>
                <span>{item.name}</span>
              </Link>
            </Menu.Item>
          );
        } else {
          // 查找一个与当前请求路径匹配的子Item
          const cItem = item.children.find(
            (cItem) => path.indexOf(cItem.path) === 0
          );
          // 如果存在, 说明当前item的子列表需要打开
          if (cItem) {
            this.setState((state) => ({
              openKey: [...state.openKey, item.path],
            }));
          }

          // 向pre添加<SubMenu>
          pre.push(
            <SubMenu
              key={item.path}
              title={
                <span>
                  {item.icon ? (
                    <>
                      <img
                        className="icon_active"
                        style={{width: '20px', height: '20px'}}
                        //src={srcList.indexOf(item.icon) != -1 ? srcListPath[srcList.indexOf(item.icon)] : success}
                        src={require('../assets/images/sider/'+item.icon+'.png')}
                        alt="icon"
                      />
                      <img
                        className="active"
                        style={{width: '20px', height: '20px'}}
                        //src={srcList.indexOf(item.icon) != -1 ? srcListPath[srcList.indexOf(item.icon)] : success}
                        src={require('../assets/images/sider/'+item.icon+'_active.png')}
                        alt="icon"
                      />  
                    </>
                  ) : null}
                  <span style={{verticalAlign:'middle'}}>{item.name}</span>
                </span>
              }
            >
              {this.getMenuNodes(item.children)}
            </SubMenu>
          );
        }
      }
      return pre;
    }, []);
  };
  
  // filterMenuItem用来根据配置信息筛选可以显示的菜单项
  filterMenuItem = (item) => {
    // if(item.hideMenu){
    //   return false;
    // }else{
    //   return true;
    // }
    return true;
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  // 获取页面头信息
  getPageHead =(path) =>{
    let index = allComments.findIndex(item => item.path === path);
    if(index !== -1){
      return allComments[index].name ?? ''
    }else{
      return ''
    }
  }

  render() {
    let {collapsed} = this.state
    const { openKey } = this.state
    let path = this.props.location.pathname;  
    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}  theme="dark" >
          <div className='logo'>
            <Avatar size={40} src={require('../assets/images/siyu_logo.jpg')} />
          </div>
          {this.state.menuTreeNode.map((item, index) => (
              <Menu
                key={item.key}
                mode="inline"
                theme="dark"
                onSelect={this.handleMenuSelect}
                selectedKeys={[path]}
                defaultOpenKeys={openKey}
                onClick={(e) =>{
                  // if(e.key === '/sidebar/create/jielong'){
                  //   stores.JieLongStore.resetParams()
                  // }
                }}
              >
                {item}
              </Menu>
          ))}
        </Sider>
        <Layout className="site-layout" id="backTop" style={{height: '100vh'}}>
          <Header className="site-layout-background system_head" style={{ padding: 0 }}>
            <div className="head_left">
              {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: this.toggle,
              })}
              <div>{this.getPageHead(path)}</div>
            </div>
            <HeadLabel />
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin:  16,
              minHeight: 'initial',
              backgroundColor: '#EFF1FA'
            }}
          >
            <Switch>
              <Redirect exact from="/" to="/index" />
                {
                  allComments.map((item, index) =>
                    <Route exact key={index} path={item.path} component={() => item.component} />
                  )
                }
              <Redirect to="/error/404" />
            </Switch>
            <BackTop target={() => document.getElementById('backTop')}>
              <div className="back_up">
                <Image preview={false} src={back_up} alt="loading"></Image>
                <div>回到顶部</div>
              </div>
            </BackTop>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(Main)