export const TokenKey = 'userToken'

export function setLocalStorage(key, vaule) {
	if(!vaule && vaule !== 0){
		return false;
	}
	if(vaule === 0) vaule = '0'
	localStorage.setItem(key, JSON.stringify(vaule));
    return true;
}
  
export function getLocalStorage(key) {
	let s = localStorage.getItem(key);
	if(s === JSON.stringify('0')) return 0
	return (s ? JSON.parse(s) : null);
}

export function removeToken() {
	localStorage.removeItem(TokenKey);
	localStorage.removeItem('userInfo');
	localStorage.removeItem('userGid');
}

export function removeTempGoos() {
	localStorage.removeItem('storage_goods');
}

