import { observable } from 'mobx'
import { setLocalStorage, getLocalStorage, TokenKey } from '../utils/storage'

class UserStore {
    @observable token = '';
    @observable userInfo = {};

    setToken = (str) =>{
        setLocalStorage(TokenKey,str)
    }
    getToken = () =>{
        let str = getLocalStorage(TokenKey)
        this.token = str
    }
    setUserInfo = (obj) =>{
        setLocalStorage('userInfo',obj)
    }
    getUserInfo = () =>{
        let obj = getLocalStorage('userInfo')
        this.userInfo = obj
    }    
}

export default UserStore;